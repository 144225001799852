/**
 *
 * Load Plugins
 * If you don't see a plugin below it might be autoloaded via webpack
 * Check webpack.mix.js for autoloaded plugins.
 *
 */
require('./scroll-converter.js');
import { TweenMax, TimelineLite, Power2, Elastic, CSSPlugin } from "gsap/TweenMax";
import { Foundation } from './foundation';
import ScrollMagic from 'scrollmagic';
import Cycle from 'jquery.cycle2';
Foundation.addToJquery(jQuery);
import tippy from 'tippy.js';
import Plyr from 'plyr';
import GLightbox from 'glightbox'
import GLightboxCss from  'glightbox/dist/css/glightbox.css';

const homepageVideoId = 'homepageVideo';

function sizeImageContainers() {

    var imageContainers = $('.section__image');

    imageContainers.each(function() {
        var img = $(this).find('img');
        $(this).width('');
        $(this).width(img.width());
    });
}

function checkHomepageVideo(){

    if( $('#'+homepageVideoId).length === 0 ) return;

    // Initialise the playr library
    const player = new Plyr('#'+homepageVideoId, {
        controls: [],
        autoplay: true,
        loop: {active: true},
        muted: true,
        fullscreen: {enabled: false},
        clickToPlay: false
    });

    player.on('ready', () => {
        sizeTheVideo();
        player.play();
    });

    // Resize the video on window resize.
    $(window).resize(function(){
        sizeTheVideo();
    });
}

function app() {

    // Vars
    var html = $('html');

    // Resets
    html.removeClass('modal-active');

    // Image container sizing - IE flexbox image workaround, sigh.
    var images = $('img');

    Foundation.onImagesLoaded(images, function() {
        sizeImageContainers();
    });

    $(window).on('resize', function() {
        sizeImageContainers();
    });

    // Check whether we should load the homepage video.
    checkHomepageVideo();

    // Horizontal scrolling
    if ($('.horizontal-scroll').length && Foundation.MediaQuery.atLeast('large')) {
        scrollConverter.activate();
    }
    else {
        scrollConverter.deactivate();
    }

    // Scroll to intro
    $('.ui-intro').click(function() {

        // Select the second section.
        let $introductionSection = $('.js--themewrapper').find('.section').eq(2);
        if( $introductionSection.length === 0 ) return false;

        // Scroll to the start of the second section
        var introPos = $introductionSection.offset().left;
        $('html, body, .scroll-wrapper').animate({
            scrollLeft: introPos
        }, 'slow');
    });

    // Scroll back to beginning
    $('.ui-back-beginning').click(function() {
        $('html, body, .scroll-wrapper').animate({
            scrollLeft: 0
        }, 'slow');
    });

    // Menu animations
    var tl,
        menu = $('.menu-modal'),
        link = $('.nav__cell'),
        logo = $('.logo'),
        close = $('.nav-icon--active');

    $('.nav-icon--trigger').click(function() {
        scrollConverter.deactivate();
        html.addClass('modal-active');
        menu.addClass('menu-modal--transition');
        tl = new TimelineLite();
        tl.to(menu, 0, {css:{className:'+=menu-modal--active'}});
        tl.to(logo, 0, {css:{className:'+=animate-fade-in'}}, '+=0.5');
        tl.to(link, 0, {css:{className:'+=animate-fade-in'}}, '+=0.25');
        tl.to(close, 0, {css:{className:'+=animate-fade-in'}}, '+=0.25');
    });

    $('.nav-icon--active').click(function() {
        if ($('.horizontal-scroll').length && Foundation.MediaQuery.atLeast('large')) {
            scrollConverter.activate();
        }
        tl = new TimelineLite();
        tl.to(logo, 0, {css:{className:'-=animate-fade-in'}});
        tl.to(link, 0, {css:{className:'-=animate-fade-in'}});
        tl.to(close, 0, {css:{className:'-=animate-fade-in'}});
        tl.to(menu, 0, {css:{className:'-=menu-modal--active'}});
        html.removeClass('modal-active');
    });

    // Scroll Magic for horizontal scrolling pages
    var controller = new ScrollMagic.Controller({
        vertical: false,
        loglevel: 2
    });

    // Slide Effects
    $('.slide-in').each(function (index, elem) {
        var scene = new ScrollMagic.Scene({
            triggerElement: elem,
            triggerHook: 0.85,
            reverse: false
        });
        scene.setClassToggle(elem, 'slide-in--active');
        controller.addScene(scene);
    });

    // Theme Dividers
    $('.divider--inactive').each(function (index, elem) {
        var scene = new ScrollMagic.Scene({
            triggerElement: elem,
            triggerHook: 0.85,
            reverse: false
        });
        scene.setClassToggle(elem, 'divider--active');
        controller.addScene(scene);
    });

    // Scroll Magic for vertical scrolling pages
    var verticalController = new ScrollMagic.Controller({
        vertical: true,
        loglevel: 2
    });

    $('.slide-up').each(function (index, elem) {
        var scene = new ScrollMagic.Scene({
            triggerElement: elem,
            triggerHook: 0.85,
            reverse: false
        });
        scene.setClassToggle(elem, 'slide-up--active');
        verticalController.addScene(scene);
    });

    $('.divider--v-inactive').each(function (index, elem) {
        var scene = new ScrollMagic.Scene({
            triggerElement: elem,
            triggerHook: 0.85,
            reverse: false
        });
        scene.setClassToggle(elem, 'divider--active');
        verticalController.addScene(scene);
    });

    // Event handler to show timeline specific information
    // This is based on a hash set via Craft when selecting a timeline entry
    $('[data-entry-id]').filter((i, el) => {

        const $el = $(el);

        // Extract the matched element Id
        const elementId = $el.data('entryId');
        if( elementId == null ) return;

        // Initialise the tippy plugin
        tippy($el.get(0), getTippyTimelineConfig(elementId, $el));
    });

    // Create a list of grouped lightbox selectors
    const lightboxElements = [];
    $('[class^="glightbox-"]').each((i, el) => {
        let selector = el.classList[0];
        if( lightboxElements.indexOf(selector) === -1 ){
            lightboxElements.push(selector);
        }
    });

    // Create a lightbox for each section's image gallery
    lightboxElements.forEach(selector => GLightbox({ selector: selector }));
}

/**
 * Resizes a video constained within a wrapper
 * @author Josh Smith <josh.smith@platocreative.co.nz>
 * @return void
 */
function sizeTheVideo() {

    var aspectRatio = 1.36;

    var video = $('#'+homepageVideoId+' iframe');

    var videoHeight = video.outerHeight();
    var newWidth = videoHeight*aspectRatio;
    var halfNewWidth = newWidth/2;

    //Define the new width and centrally align the iframe
    video.css({"width":newWidth+"px","left":"50%","margin-left":"-"+halfNewWidth+"px"});
}

var loaded = true;

// On Turbolinks load
$(document).on('turbolinks:load', function() {
    if(!loaded) {
        $(document).foundation();
    }
    loaded = false;
    app();
});

$(function() {
    if(loaded) {
        $(document).foundation();
    }
});

/**
 * Returns a config object for tippy timelines
 * @author Josh Smith <josh.smith@platocreative.co.nz>
 * @return object A config object
 */
function getTippyTimelineConfig(elementId, $el){

    const config = {

        onShow(instance) {

            // instance.setContent('Loading...');

            fetch('/api/v1/timelines/'+elementId)
                .then(resp => resp.json())
                .then(data => {

                    // Define layout options for the popup
                    let layoutDirection = 'horiz';
                    const layoutClass = 'timeline-figure';

                    // Create and append the timeline template
                    const template = document.getElementById('timelinePopup');
                    const container = document.createElement('div');
                    container.appendChild(document.importNode(template.content, true));

                    // Create an image if we have image data
                    if( typeof data.image === 'object' ) {

                        const img = container.getElementsByClassName('js--timeline-img')[0];

                        // Update src, height and width attribute to display correctly.
                        img.setAttribute('src', data.image.url);
                        img.setAttribute('height', data.image.height);
                        img.setAttribute('width', data.image.width);

                        // Apply specific classes to adjust the content layout
                        layoutDirection = (data.image.height > data.image.width ? 'horiz' : 'vert');
                    }

                    // Set the image caption & link URL
                    container.getElementsByClassName('js--timeline-cap')[0].innerHTML = data.content;
                    container.getElementsByClassName('js--timeline-link')[0].setAttribute('href', $el.attr('href'));
                    container.getElementsByClassName('js--timeline-figure')[0].classList.add(`${layoutClass}--${layoutDirection}`);

                    // Insert the template into the container
                    instance.setContent(container.innerHTML);

                }).catch(err => {
console.log(err);
                });

        },
        theme: 'exhibitions',
        interactive: true,
        animation: 'fade',
        animateFill: false

    };

    return config;
}
